<template>
  <div>
    <div class="layout-container mb-48 md:mb-64">
      <div
        class="sm:flex"
        :class="{
          'sm:align-center': model.showAsCollage,
        }"
      >
        <div
          class="relative w-full flex items-center justify-center bg-center bg-no-repeat aspect-4/3 sm:w-1/2"
          :class="{
            'md:order-last': model.showImageRight,
            'sm:pr-12 md:pr-24': !model.showImageRight && !model.showAsCollage,
            'sm:pl-12 md:pl-24': model.showImageRight && !model.showAsCollage,
          }"
        >
          <div
            v-if="model.showAsCollage && model.images"
            ref="target"
            class="w-full h-full relative mb-64 md:mb-0 borde border-black"
          >
            <div
              v-if="model.images[0]"
              class="animate3 absolute rounded-md aspect-4/3 w-full left-0 top-[15%] w-[70%] transition-all duration-700 ease-in-out will-change-transform"
            >
              <nuxt-img
                :src="getImagePrefix(model.images[0].url)"
                class="rounded-md"
                preset="standard"
                sizes="sm:100vw md:606px"
                :alt="model.images[0].expanded?.alternativeText"
              />
            </div>
            <div
              v-if="model.images[1]"
              class="animate2 absolute rounded-md aspect-4/3 w-full right-0 top-[5%] w-[70%] transition-all duration-500 ease-in-out will-change-transform"
            >
              <nuxt-img
                :src="getImagePrefix(model.images[1].url)"
                class="rounded-md"
                preset="standard"
                sizes="sm:100vw md:606px"
                :alt="model.images[1].expanded?.alternativeText"
              />
            </div>
            <div
              v-if="model.images[2]"
              class="animate1 absolute rounded-md aspect-4/3 top-[20%] w-[80%] left-[70px] transition-all duration-500 ease-in-out will-change-transform"
            >
              <nuxt-img
                :src="getImagePrefix(model.images[2].url)"
                class="rounded-md object-cover w-full h-full"
                preset="standard"
                sizes="sm:100vw md:606px"
                :alt="model.images[2].expanded?.alternativeText"
              />
            </div>
          </div>
          <video
            v-else-if="model.backgroundVideo"
            class="absolute top-0 left-0 h-full object-cover w-full rounded-md w-[calc(100%-1.5rem)]"
            muted
            loop
            autoplay
            playsinline
          >
            <source
              :src="model.backgroundVideo.url"
              type="video/mp4"
            >
          </video>
          <nuxt-img
            v-else-if="model.backgroundImage"
            class="rounded-md bg-cover aspect-4/3"
            :src="getImagePrefix(model.backgroundImage.url)"
            loading="lazy"
            sizes="sm:100vw md:606px"
            preset="standard"
            :alt="model.backgroundImage.url"
          />
        </div>
        <div
          class="flex pt-24 sm:py-56 flex-shrink-0 sm:w-1/2 sm:flex sm:items-center"
          :class="{
            'sm:pr-32 sm:pl-32 md:pl-88': !model.showImageRight,
            'sm:pl-32 sm:pr-32 md:pr-88': model.showImageRight,
          }"
        >
          <WysiwygWrapper
            v-if="model.content"
            v-epi-edit="'Content'"
            :style-config="{ 'wysiwyg wysiwyg--sm-text': true }"
            :html="model.content"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TextImageVideoBlock } from '~/content-types';
import { onBeforeUnmount, computed, onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useUiStore } from '~/store/ui';
const uiStore = useUiStore();

import useImages from '~/composables/useImages';
const { getImagePrefix } = useImages();

defineProps<{
  model: TextImageVideoBlock,
}>();

const target = ref<HTMLTextAreaElement | null>(null);
const startAt = ref(0);
const divHeight = ref(0);
const scrollPos = ref(0);
const windowHeight = ref(0);

const setScroll = () => {
  scrollPos.value = window.scrollY;
};

const percentInView = computed(()=> {
  const abovePixels = scrollPos.value + windowHeight.value - startAt.value - 400;
  const endsAt = windowHeight.value + divHeight.value + 400;
  if (abovePixels <= 0) {
    return 0;
  }
  const percent = abovePixels / endsAt;
  if (percent >= 1) {
    return 1;
  }
  return percent;
});

const percentInView2 = computed(()=> {
  const abovePixels = scrollPos.value + windowHeight.value - startAt.value - 400;
  const endsAt = windowHeight.value + divHeight.value + 400;
  if (abovePixels <= 0) {
    return 0;
  }
  const percent = abovePixels / endsAt;
  if (percent >= 1) {
    return 1;
  }
  return percent;
});

const { isMobile } = storeToRefs(uiStore);

const animation1 = computed(()=> {
  const moveY = isMobile ? 50 : 200;
  const thisY = (moveY + 800) * percentInView.value - (isMobile ? 20 : 200);
  return {
    transform: `translate3d(0px, -${thisY}px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
  };
});
const animation2 = computed(()=> {
  const moveY = isMobile ? 50 : 200;
  const thisY = (moveY + 200) * percentInView2.value;
  return {
    transform: `translateY(-${thisY}px)`,
  };
});

const animation3 = computed(()=> {
  const moveY = isMobile ? 50 : 200;
  const thisY = (moveY + 100) * percentInView2.value;
  return {
    transform: `translateY(-${thisY}px)`,
  };
});

const getPositions = () => {
  if (target.value) {
    windowHeight.value = window.innerHeight;
    startAt.value = target.value.getBoundingClientRect().y + window.scrollY;
    divHeight.value = target.value.getBoundingClientRect().height;
  }
};

onMounted(() => {
  getPositions();
  document.addEventListener('scroll', setScroll);
  document.addEventListener('resize', getPositions);
});
onBeforeUnmount(()=> {
  document.removeEventListener('scroll', setScroll);
  document.removeEventListener('resize', getPositions);
});

</script>
